<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            <v-layout wrap>
              <v-flex xs12 sm6 md8 text-left align-self-center>
                <span> Purchases </span>
              </v-flex>
              <v-flex md4 align-self-center>
                <v-layout wrap justify-end>
                  <v-flex xs12 sm6 md6 lg6 xl6 align-self-center>
                    <v-btn
                      tile
                      class="px-5"
                      small
                      outlined
                      :href="ipURL + '/purchase/reportadminpdf'"
                      target="_blank"
                      color="warning"
                      style="text-transform: none"
                    >
                      <span
                        style="
                          font-size: 14px;
                          font-family: poppinssemibold;
                          color: #000;
                        "
                      >
                        Download Pdf</span
                      >
                    </v-btn>
                  </v-flex>
                  <v-flex xs12 sm6 md6 lg6 xl6 align-self-center>
                    <v-btn
                      tile
                      small
                      outlined
                      color="warning"
                      style="text-transform: none"
                    >
                      <download-excel :data="user" :fields="json_fields">
                        <span
                          style="
                            font-size: 14px;
                            font-family: poppinssemibold;
                            cursor: pointer;
                            color: #000;
                          "
                        >
                          Download Excel</span
                        >
                      </download-excel>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card-title>
          <v-layout wrap class="pt-10">
            <!-- <v-col cols="12" sm="3" md="3">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                solo
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-col> -->
            <v-flex xs12 sm3 pr-0 pr-sm-4>
              <v-autocomplete
                v-model="productid"
                :items="productList"
                outlined
                item-text="productname"
                item-value="_id"
                dense
                clearable
                label="Product"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12 sm3 pr-0 pr-sm-4>
              <v-autocomplete
                v-model="sellerid"
                :items="sellerList"
                item-text="organization"
                item-value="_id"
                outlined
                clearable
                dense
                label="Seller"
              ></v-autocomplete>
            </v-flex>
            <v-flex xs12 sm3 md2>
              <v-select
                dense
                :items="items"
                v-model="deliverystatus"
                label="Delivery Status"
                outlined
              ></v-select>
            </v-flex>
            <v-flex xs12 sm3 md2 pl-0 pl-sm-4>
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="from"
                    label="From"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="from"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm3 md2 pl-0 pl-sm-4>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="to"
                    label="To"
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="to"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-start v-if="user">
            <template v-for="(item, i) in user">
              <v-flex pa-6 xs12 xl11 :key="item._id" pr-lg-6 pr-xl-0>
                <v-layout wrap>
                  <v-flex xs12 sm12 md12 lg7>
                    <v-layout wrap justify-start>
                      <v-flex xs12 sm3 md3 lg2 xl2 pr-md-2>
                        <!-- <v-img
                          :src="baseURL + item.product[0].photos[0]"
                          aspect-ratio="1"
                          contain
                          height="100px"
                        ></v-img> -->
                        <v-card height="100" tile flat>
                          <div style="margin: 0; height: 100%">
                            <Collage
                              :images="item.photos"
                              style="object:fit:contain!important"
                              :collageSizeMin="1"
                              :collageSizeMax="4"
                              :showNoImagesMsg="true"
                              noImagesMsg="No Images"
                              :showLoadingMsg="false"
                              loadingMsg="Loading..."
                              height="100px"
                              width="100px"
                            >
                            </Collage>
                          </div>
                        </v-card>
                      </v-flex>
                      <v-flex xs12 sm9 md9 lg9 xl9>
                        <v-layout wrap justify-center fill-height>
                          <v-flex xs12>
                            <v-layout wrap justify-start>
                              <v-flex lg12 text-left>
                                <span style="font-size: 16px; color: #000000">
                                  {{ item.productname }}
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 align-self-end>
                            <v-layout wrap justify-start>
                              <v-flex xs6 sm6 md3 lg3 xl3>
                                <v-layout wrap>
                                  <v-flex text-left xs12>
                                    <span
                                      style="color: #8d8d8d; font-size: 14px"
                                    >
                                      Name
                                    </span>
                                  </v-flex>
                                  <v-flex text-left xs12>
                                    <span style="font-size: 16px">
                                      {{ item.firstname }}
                                      {{ item.lastname }}
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs6 sm6 md3 lg3 xl3>
                                <v-layout wrap>
                                  <v-flex text-left xs12>
                                    <span
                                      style="color: #8d8d8d; font-size: 14px"
                                    >
                                      Phone
                                    </span>
                                  </v-flex>
                                  <v-flex text-left xs12>
                                    <span style="font-size: 16px">
                                      {{ item.mobile }}
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs3 sm3 md3 lg3 xl3>
                                <v-layout wrap>
                                  <v-flex text-left xs12>
                                    <span
                                      style="color: #8d8d8d; font-size: 14px"
                                    >
                                      Amount
                                    </span>
                                  </v-flex>
                                  <v-flex text-left xs12>
                                    <span style="font-size: 16px">
                                      Rs. {{ item.amount }}
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                              <v-flex xs3 sm3 md3 lg3 xl3>
                                <v-layout wrap>
                                  <v-flex text-left xs12>
                                    <span
                                      style="color: #8d8d8d; font-size: 14px"
                                    >
                                      Payment
                                    </span>
                                  </v-flex>
                                  <v-flex text-left xs12>
                                    <span style="font-size: 16px">
                                      {{ item.method }}
                                    </span>
                                  </v-flex>
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex text-left xs1 text-center hidden-md-and-down>
                        <v-divider vertical></v-divider>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex text-left xs12 md12 lg5>
                    <v-layout wrap justify-space-between>
                      <v-flex sm3 md3 lg3 xl3>
                        <v-layout wrap fill-height>
                          <v-flex xs6 sm12>
                            <span style="color: #8d8d8d; font-size: 14px">
                              Delivery Status
                            </span>
                          </v-flex>
                          <v-flex xs6 sm12 text-left align-self-start>
                            <span
                              v-if="item.details.length > 0"
                              style="font-size: 16px"
                            >
                              {{ item.details[0].deliverystatus }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex sm3 md3 lg3 xl3>
                        <v-layout wrap fill-height>
                          <v-flex xs6 sm12>
                            <span style="color: #8d8d8d; font-size: 14px">
                              Purchase Type
                            </span>
                          </v-flex>
                          <v-flex xs6 sm12 text-left align-self-start>
                            <span style="font-size: 16px">
                              {{ item.purchasetype }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex sm3 md3 lg3 xl3>
                        <v-layout wrap fill-height>
                          <v-flex xs6 sm12>
                            <span style="color: #8d8d8d; font-size: 14px">
                              Discount
                            </span>
                          </v-flex>
                          <v-flex xs6 sm12 text-left align-self-start>
                            <span style="font-size: 16px">
                              {{ item.discount }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs3 lg3 sm3 md3 text-center align-self-end>
                        <v-btn
                          tile
                          block
                          small
                          outlined
                          color="warning"
                          style="text-transform: none"
                          @click="info(item._id)"
                        >
                          <span style="color: #000; font-size: 14px">
                            View
                          </span>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 xl11 :key="i">
                <v-divider></v-divider>
              </v-flex>
            </template>
          </v-layout>
          <div class="pt-2">
            <v-pagination
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
              color="#FF0000"
            ></v-pagination>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Collage from "vue-collage";
import axios from "axios";

export default {
  components: {
    Collage,
  },
  data() {
    return {
      appLoading: false,
      search: "",
      currentPage: 1,
      pages: 0,
      count: 20,
      totalData: 0,
      deliverystatus: null,
      menu1: false,
      menu2: false,
      date: new Date(),
      from: null,
      to: null,
      totalRows: 0,
      ServerError: false,
      headers: [
        { text: " Name", value: "firstname" },
        { text: "Products", value: "productname", tdClass: "tdwidth" },
        { text: "Amount", value: "amount" },
        { text: "Order Date", value: "Orderdate" },
        { text: "Delivery Status", value: "deliverystatus" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      user: [],
      data: [],
      category: [],
      items: [
        "Placed",
        "Cancelled",
        "Shipped",
        "In Transit",
        "Delivered",
        "Return",
      ],
      productList: [],
      sellerList: [],
      sellerid: null,
      productid: null,
      json_fields: {
        FirstName:"firstname",
        LastName:"lastname",
        ProductName:"productname",
        Phone:"mobile",
        Email: "email",
        Amount:"amount",
        Payment:"method",
        Discount:"discount",
        Date: "createddate",
      },
    };
  },
  mounted() {
    this.getData();
    this.getProductList();
    this.getSellerList();
  },
  watch: {
    from() {
      this.getData();
    },
    to() {
      this.getData();
    },
    currentPage() {
      this.getData();
    },
    deliverystatus() {
      this.getData();
    },
    productid() {
      this.getData();
    },
    sellerid() {
      this.getData();
    },
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/purchase/reportadmin",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: this.count,
          page: this.currentPage,
          from: this.from,
          to: this.to,
          deliverystatus: this.deliverystatus,
          productid: this.productid,
          sellerid: this.sellerid,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          var vm = this;
          this.user.forEach(function (itm) {
            itm.createddate = vm.formatDate(itm.createddate);
            itm.photos = [];

            if (itm.product.length > 0) {
              itm.product.forEach(function (itm2) {
                if (itm2.photos.length > 0) {
                  itm.photos.push({
                    image: vm.baseURL + itm2.photos[0],
                  });
                }
                // photos.map(function (obj) {
                //   obj.image = vm.baseURL + obj.image;
                //   return obj;
                // });
              });
            }
          });
          this.pages = response.data.pages;
          this.data = response.data.data.category;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getProductList() {
      // this.appLoading = true;
      axios({
        url: "/product/dropdown",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          this.productList = response.data.data;
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getSellerList() {
      // this.appLoading = true;
      axios({
        url: "/seller/dropdown",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          this.sellerList = response.data.data;
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    info(pid) {
      this.$router.push({
        name: "purchaseDetails",
        params: { purchaseid: pid },
      });
    },
    edit(pid) {
      this.$router.push({ name: "editProduct", params: { purchaseid: pid } });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>

<style>
.tdwidth {
  width: 50% !important;
}
</style>